@import '~bootstrap/scss/bootstrap';



*{
  margin: 5px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}
.form-model{
  width: 100px;
}

.contact-form-container{
    display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  width: 100%;
//   max-width: 500px;
  margin: 0 auto;
  .sometext{
    max-width: 1000px;
  }
  .contact-form-details{
    display: flex;
    box-shadow: 2px 10px 32px -8px rgba(0, 0, 0, 0.75);
    border-radius: 15px;
    max-width: 80%;
    max-height: 50%;

    .contact-details{
        padding: 10px;
        width: 40%;
        
        img{
          height: 25px;
          width: 25px;
        }
        .details{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 30%;
    
        }
    }
    .form-container{
      padding: 40px;
      width: 60%;
      .mb-3{
        display: flex;
        .form-label{
          font-weight: bold;
          width: 35%;
        }
       
      }
    }

  }
}

@media only screen and (max-width: 800px) {
.contact-form-container{

  .contact-form-details {
    margin: 0;
    max-width: 90%;
    max-height: fit-content;
      flex-direction: column-reverse;
      .contact-details{
        width: 100%;
        margin: 0;
        padding: 5px;
        *{
          margin: 0;
          padding: 5px 5px;
        }
      }
      .form-container{
        width: 100%;
        padding: 20px 10px;
        .mb-3{
          display: flex;
          .form-label{
            font-weight: bold;
            width: 35%;
          }
          .form-button{
            align-items: center;
            justify-content: center;
          }
        }
      }
  }
}
}
// form{
    
//     // background-color: #f2f2f2;
//     // padding: 20px;

//     // border-radius: 10px;
//     // width: 50%;
//     // height: 50%;
//     .mb-3{
//         display: flex;
//     }
//     .form-label{
//         width: 25%;

//     }
//     .form-control{
//         width: 75%;
//     }
// }

// .form-button{
//     display: flex;
    
// }
// .button{
//     margin: 10px;
// }

// .whole-form{     
//   align-items: center;
//   justify-content: center;

// }

// .form-container {

//     box-shadow: 2px 10px 32px -8px rgba(0, 0, 0, 0.75);
//     width: 40%;
//     margin:50px;

//     border-radius: 15px;
//     background-color: #f2f2f2;
//     padding: 20px;
//   }
  

// form *{
//     width: 85%;
//     height: 30%;
// }
// .row::after{
//     content: "";
//     display: table;
//     clear: both;
//     font-size: larger;}